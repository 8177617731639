@mixin app-font($size, $line-height, $weight : normal, $style : normal ) {
    font-family: 'Mulish', Muli, sans-serif !important;
    font-style: $style !important;
    font-weight: $weight !important;
    font-size: $size !important;
    line-height: $line-height !important;
}

@mixin mediumFormFontSize() {
    @include app-font(16px, 22px)
}

@mixin smallFormFontSize() {
    @include app-font(14px, 18px)
}

@mixin pageContainerSpacing($spaceType) {
    #{$spaceType}: 0 74px 0 74px;

    @media screen and (max-width: 1024px) {
        #{$spaceType}-left: 30px;
        #{$spaceType}-right: 30px;
    }

    @media screen and (max-width: 425px) {
        #{$spaceType}-left: 15px;
        #{$spaceType}-right: 15px;
    }
}

@mixin pageContainerHeader($margin-top: 50px, $background: #f6f9fc) {
    @include pageContainerSpacing("padding");
    margin-bottom: 3px;
    padding-bottom:  30px;
    padding-top: $margin-top;
    background-color: rgba(0, 0, 0, 0);

    &.stickyHeader {
      z-index: 11;
      background-color: $background;
      position: sticky;
      top: 0;
    }

    &.noPadding {
     padding: 0;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 700px) {
      flex-wrap: wrap;

      & > div:first-child {
        margin-bottom: 15px;
      }
    }
}