$blue-primary: #16b296;
$blue-dark: #129873;

$red-primary: #F5222D;
$red-light: #EB5757;

$blue-gray: #47505E;

$grey-primary: #375a64;
$grey-dark: #343a40;
$grey-little-dark: #585858;
$grey-less-light: #a1a1a1;
$grey-light: #a7b7bb;
$grey-little-light: #D9D9D9;
$grey-lighter: #DADADA;
$grey-much-lighter: #DDDDDD;

$yellow-primary: #FAAD14;
$orange-primary: #FFA39E;

$grey-white: #F5F5F5;
$less-white: #FAFAFA;
$white: #FFFFFF;

// ==================
// New color palette
// ==================

// Primary colors
$primary: #16B396;
$primary-dark: #129873;
$primary-faded: rgba(22, 179, 150, 0.5);

// Secondary colors
$gray-70: #24272B;
$gray-60: #47505E;
$gray-50: #767B84;
$gray-40: #A8ADB8;
$gray-30: #D1D3D9;
$gray-20: #EEEFF1;
$gray-10: #F6F7F8;

// Backgrounds
$background-white: #FFFFFF;
$background-white-shaded: #FBFBFB;
$background-white-shaded: #f6f9fc;
$background-white-darker: #F7F7F7;
$background-dark: #343B46;
$background-dark-lighter: rgba(0, 0, 0, 0.7);

// Status
$success: #16B296;
$success-dark: #129873;
$success-faded: rgba(22, 178, 150, 0.2);

$warning: #F09F55;
$warning-dark: #D38136;
$warning-faded: rgba(240, 159, 85, 0.2);

$error: #FF6161;
$error-dark: #E93030;
$error-faded: rgba(255, 97, 97, 0.2);

$blue: #1890ff;
$blue-faded: #e6f7ff;

// Social
$facebook-color: #3B5998;
$instagram-color: #D93175;
$google-color: #DF4A32;
$linkedin-color: #007BB6;
$pinterest-color: #CB2027;
$twitter-color: #00B6F1;

// Alias
$page-background: #FBFBFB;