@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';

div.custom-alert.ant-alert {
  width: max-content;
  max-width: 620px;
  padding: 16px 17px;

  border: none;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 18, 71, 0.16);

  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    height: 100%;
    width: 4px;

  }

  &.alert {
    &-white {
      background-color: $white;

      &:before {
        background-color: $success;
      }
    }

    &-green {
      background-color: rgba($success, .1);

      &:before {
        background-color: $success;
      }
    }

    &-gray {
      background-color: $gray-20;

      &:before {
        background-color: $gray-30;
      }
    }

    &-red {
      background-color: rgba($error, .1);

      &:before {
        background-color: $error;
      }

      .alert-action {
        margin-top: 16px;
        color: $error;

        &:hover {
          color: $error-dark;
        }
      }
    }
  }

  .ant-alert-message {
    @include app-font(16px, 20px, bold);
    color: $gray-70;
    margin: 0;
    padding-right: 20px;
  }

  .ant-alert-description {
    margin-top: 16px;

    .alert-description-text {
      color: $gray-60;
      margin: 0;
    }
  }

  button.ant-alert-close-icon {
    top: 15px;
  }

  .alert-action {
    border: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    @include app-font(14px, 18px, 700);
    transition: color 300ms ease;
    color: $success;

    &:hover {
      color: $success-dark;
    }
  }
}