@import './colors.scss';
@import './mixins.scss';

:root {
  --main-color: #16b296;
  --main-dark-color: #129873;
}

.negative-currency {
  color: $error-dark !important;
}

/* Ant Design Overrides */
.ant-layout {
  background-color: $page-background;
}

p {
  margin-bottom: 0;
}

.ant-form-item.no-margin-bottom {
  margin-bottom: 0;
}

.ant-form-item-explain {
  text-align: left;
}

.ant-form-item-has-error .ant-form-item-explain {
  text-align: left;
}

.ant-input .ant-input-prefix,
.ant-input .ant-input-suffix,
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  display: flex;
  align-items: center;

  svg path {
    fill: $gray-50;
  }
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: $error;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.ant-input:not(.ant-input-disabled):not(.ant-input-affix-wrapper-disabled):not(:focus):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $gray-50;
}

.ant-form-item:not(.ant-form-item-has-error) .ant-input-affix-wrapper-focused:not(.ant-form-item-has-error),
.ant-form-item:not(.ant-form-item-has-error) .ant-input:not(.ant-form-item-has-error):focus {
  outline: none;
  box-shadow: none;
  border-color: $primary !important;
}

.ant-input.ant-input-disabled,
.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
  background: $gray-30;

  &,
  &::placeholder,
  & input::placeholder {
    color: $gray-70;
  }
}

.ant-picker {
  width: 100%;
}

.ant-picker,
.ant-picker.ant-picker-range {
  height: 36px;
  border: 1px solid $gray-30;
  border-radius: 4px;
  box-shadow: none;

  input {
    color: $gray-70;
  }

  .ant-picker-active-bar {
    background: $primary;
  }

  &.ant-picker-focused:not(.ant-picker-disabled) {
    border-color: $primary;
  }

  &:not(.ant-picker-disabled):not(.ant-picker-focused):hover {
    box-shadow: none;
    border-color: $gray-50;
  }

  &.ant-picker-disabled {
    color: $gray-70;
    border-color: $gray-30;
    background: $gray-30;
  }
}

.ant-picker-ok {
  .ant-btn-primary:not([disabled]) {
    background: $primary;
    color: white;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: $grey-little-light;
}

.ant-picker-date-panel {
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $primary;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $success;

    &:before {
      border-color: $success-dark;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: $success-faded;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.ant-radio-wrapper::after {
  display: none;
}

.ant-radio-group {
  .ant-radio-wrapper:not(:last-child) {
    margin-bottom: 10px;
  }
}

.ant-form-item .ant-radio-group {
  border: 1px solid rgba(0,0,0,0);
}

.ant-form-item-has-error .ant-radio-group {
  border-color: $error;
}

.ant-tabs-ink-bar {
  z-index: 0;
}

// Fullwidth Form item labels
.faded-form-item,
.ant-form-item-label.ant-col-24 {
  padding: 0;
  margin: 0;

  label {
    color: $gray-50;
    height: 15px;
    font-size: 12px;
    line-height: 15px;
  }
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $primary;
}

// Pagination
.ant-pagination {
  margin: 16px 3px;

  .ant-pagination-item {
    min-width: 20px;
    background: rgba(0,0,0,0);
    border: none;

    a {
      @include app-font(18px, 22px, bold);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: $gray-40;
    }

    &:hover a {
      color: $success;
    }

    &-active a {
      color: $success;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    background: $white;
    box-shadow: 0 0 2px rgba(0, 0, 33, 0.14), 0 1px 3px rgba(0, 18, 71, 0.2);
    transition: all 300ms ease;

    &.ant-pagination-disabled {
      opacity: 0.25;
    }

    .ant-pagination-item-link {
      border: none;
      color: $primary;
      transition: all 300ms ease;
    }

    &:not(.ant-pagination-disabled):hover {
      box-shadow: 0 0 2px rgba(0, 0, 33, 0.14), 0 1px 3px rgba(0, 18, 71, 0.4);

      .ant-pagination-item-link {
        border: none;
        color: $primary-dark;
      }
    }
  }
}

.ant-spin-dot-item {
  background: $primary;
}

.ant-form-item-has-error {
  .ant-select-auto-complete {
    border-color: #ff4d4f;
  }
}

.ant-modal-header {
  border-bottom: none;
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
  cursor: initial;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: transparent;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: $primary;
}

.ant-descriptions-row:last-child .ant-descriptions-item {
  padding-bottom: 0;
}

.ant-space {
  width: 100%;
}

.rc-virtual-list {
  .ant-select-item {
    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
    }


    .ant-select-item-option-content {
      overflow: visible;
      white-space: normal;
    }
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill::first-line,
input[type=password]:-webkit-autofill,
input[type=password]:-webkit-autofill:hover,
input[type=password]:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #222;
  background: #FFF;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

// ANIMATIONS
.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.fade-in-left {
	-webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes scale-in-center {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}