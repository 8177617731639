@import 'styles/mixins.scss';
@import 'styles/colors.scss';

.rpp {
  max-width: 440px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-container {
    width: 100%;
    // min-height: 400px;
    margin: 0 auto;
    background: white;
    padding: 2rem;
    display: grid;
    grid-template-rows: auto 1fr auto;
    position: relative;

    &-title {
      @include app-font(22px, 24px, bold);
      color: $grey-primary;
      margin-bottom: 3rem;
    }

    &-submit {
      min-width: 150px;
    }
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    opacity: 0.5;
    z-index: 9999;
  }
}
