@import './styles/colors.scss';
@import './styles/_sizes.scss';

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: rgba(71, 80, 94,.7);
}

body {
  font-family: 'Mulish', Muli, sans-serif !important;
  font-size: 14px !important;

  #root {
    height: 100vh;
    overflow: auto;

    @media screen and (max-width: 768px) {
      height: -webkit-fill-available;
    }
  }
}

.appleLink {
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
}

.item-container-trash {
  color: #375a64;
}

.slide-down-title {
  animation: 1.5s ease 0s 0.8 slideDownTitle;
}

.App {
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    min-height: -webkit-fill-available;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-right {
  justify-content: flex-end;
}

.justify-content-left {
  justify-content: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
}

.align-items-bottom {
  align-items: flex-end;
}

// Main App Styles
$impersonation-banner-height: 60px;

.auth-main-app-container {
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;

  &.with-impersonation {
    max-height: calc(100vh - (64px + #{$impersonation-banner-height}));

    & > div:first-child {
      min-height: calc(100vh - (64px + #{$impersonation-banner-height}));
    }
  }
}

.main-app-container {
  scroll-behavior: smooth;
  font-family: 'Mulish', sans-serif;
  color: $gray-70;
  background-color: $background-white-shaded;
  max-height: 100%;
  overflow: auto;
}

.main-app-container > div:first-child {
  // min-height: 70vh;
  min-height: calc(100vh - 64px);
}

.main-app-container .onboarding {
  background-color: $background-white-shaded;
  font-family: 'Mulish', sans-serif;
  min-height: 100vh !important;
  padding-top: 50px;
  margin-top: 0;
  @media screen and (max-width: 768px) {
    min-height: -webkit-fill-available !important;
  }

  .page-title {
    font-weight: 800;
    font-size: 33px;
    color: #333333;
    justify-content: center;
    line-height: 1.2;
    width: 100%;
    margin-top: -50px;
    margin-bottom: 45px;
    text-align: center;
  }

  .submit-buttons {
    flex-grow: 1;
    display: flex;
    .ant-btn {
      width: 100%;
    }
  }

  .ant-space {
    width: 100%;

    .ant-space-item {
      width: 100%;
    }
  }
}



/* Antd Global */
label {
  font-weight: 400;
}

a[disabled] {
  pointer-events: none;
}

.ant-layout a:hover {
  color: $primary-dark !important;
  text-decoration: none;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: $primary-dark;
}

.ant-badge-count {
  font-size: 11px !important;
}

.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      padding: 12px 16px;
      box-shadow: 0 0 10px rgba(0, 18, 71, 0.08), 0 14px 28px rgba(0, 18, 71, 0.15);
      color: $gray-70;
    }

    .ant-tooltip-arrow-content,
    .ant-tooltip-arrow-content::before,
    .ant-tooltip-inner,
    .ant-tooltip-inner::before {
      background-color: $white;
      background: $white;
    }
  }
}

.ant-form-item-extra {
  text-align: left;
}

.ant-picker-year-panel {
  td.ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background: $primary;
    }
  }
}

.rowOfCards {
  .ant-col {
    > .box {
      height: 100%;
    }
  }
}

.mapboxgl-map {
  .mapboxgl-canvas {
    width: 100% !important;
  }
}

@media screen and (max-width: #{$tablet-viewport}) {
  #ipad,
  #iphone {
    .ant-notification {
      position: absolute;
      margin-top: 40px;
    }
  }
}